





















































import {
	Component,
	Vue
} from "vue-property-decorator"
import {
	apiUserLevelList,
	apiUserLevelDel
} from "@/api/user/user"
import {
	PageMode
} from "@/utils/type"
import {
	RequestPaging
} from "@/utils/util"
import LsDialog from "@/components/ls-dialog.vue"
import LsPagination from "@/components/ls-pagination.vue"
@Component({
	components: {
		LsDialog,
		LsPagination
	},
})
export default class MemberGrade extends Vue {

	/** S Data **/
	// 分页请求
	pager: RequestPaging = new RequestPaging();
	/** E Data **/

	/** S Methods **/
	// 获取用户等级列表
	getUserLevelList() {
		this.pager
			.request({
				callback: apiUserLevelList,
				params: {},
			})
			.catch(() => {
				this.$message.error("数据请求失败，刷新重载!");
			});
	}
	// 新增用户等级
	onUserLevelAdd() {
		this.$router.push({
			path: "/user/grade_edit",
			query: {
				mode: PageMode["ADD"],
			},
		});
	}
	// 编辑用户等级
	onUserLevelEdit(item: any) {
		this.$router.push({
			path: "/user/grade_edit",
			query: {
				mode: PageMode["EDIT"],
				id: item.id,
				level: item.rank,
			},
		});
	}
	
	// 查看用户等级
	onUserLevelSee(item: any) {
		this.$router.push({
			path: "/user/grade_edit",
			query: {
				mode: PageMode["EDIT"],
				id: item.id,
				level: item.rank,
				disabled: 'true',
			},
		});
	}

	// 删除用户等级
	onUserLevelDel(item: any) {
		apiUserLevelDel({
			id: item.id as number
		}).then(() => {
			this.getUserLevelList();
			this.$message.success("删除成功!");
		}).catch(() => {
			this.$message.error("删除失败")
		})
	}
	/** E Methods **/
	/** S Life Cycle **/
	created() {
		this.getUserLevelList();
	}

	/** E Life Cycle **/
}
